// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal-background__xvTgR {
  position: absolute;
  z-index: 2;
  background-color: rgba(128, 128, 128, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.Modal_modal__yEgVW {
  width: 80%;
  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss","webpack://./src/styles/constants.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,UCJc;EDMd,0CAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,OAAA;EACA,MAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;AAFF","sourcesContent":["@import \"../../styles/reference.module.scss\";\n\n.modal-background {\n  position: absolute;\n  z-index: $modal-z-index;\n  // opacity: 0.5;\n  background-color: rgba(gray, 0.5);\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  left: 0;\n  top: 0;\n}\n\n.modal {\n  width: 80%;\n  padding: 1rem;\n  border-radius: 1rem;\n  background-color: rgba(white, 1);\n  border: 1px solid black;\n}\n","$modal-z-index: 2;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-background": `Modal_modal-background__xvTgR`,
	"modal": `Modal_modal__yEgVW`
};
export default ___CSS_LOADER_EXPORT___;
