// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GameList_game-link__jjGTi {
  border: 1px solid gray;
  background: #fafafa;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  color: black;
  text-decoration: none;
  justify-content: space-between;
  padding-bottom: 0;
}

.GameList_game-list__upuFZ {
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 2rem;
  width: 100%;
}

.GameList_game-link-text__14QsT {
  text-decoration: none;
  text-align: center;
  font-size: 0.8rem;
}

.GameList_game-link-icon__e2KHh {
  font-size: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/GameList/GameList.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,qBAAA;EACA,8BAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".game-link {\n  border: 1px solid gray;\n  background: #fafafa;\n  border-radius: 5px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 1rem;\n  color: black;\n  text-decoration: none;\n  justify-content: space-between;\n  padding-bottom: 0;\n}\n\n.game-list {\n  display: grid;\n  grid-template-columns: 40% 40%;\n  column-gap: 2rem;\n  width: 100%;\n}\n\n.game-link-text {\n  text-decoration: none;\n  text-align: center;\n  font-size: 0.8rem;\n}\n\n.game-link-icon {\n  font-size: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"game-link": `GameList_game-link__jjGTi`,
	"game-list": `GameList_game-list__upuFZ`,
	"game-link-text": `GameList_game-link-text__14QsT`,
	"game-link-icon": `GameList_game-link-icon__e2KHh`
};
export default ___CSS_LOADER_EXPORT___;
