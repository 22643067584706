type NumberInputProps = {
  value: number | null;
  setValue: (_: number | null) => void;
  extraClasses?: string;
};

export default function NumberInput(props: NumberInputProps) {
  return (
    <input
      type="text"
      className={props.extraClasses || ""}
      value={props.value === null ? "" : `${props.value}`}
      onChange={event => {
        const v = event.target.value;
        if (v === '') {
          props.setValue(null);
        } else {
          props.setValue(Number.parseInt(v));
        }
      }}
    />
  );
}
