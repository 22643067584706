import styles from "./Navigation.module.scss";
import { ReactNode } from 'react';
import icon from "../icon.png";
import { Link, Routes, Route } from "react-router-dom";
import GameList from "GameList";
import HomePage from "HomePage";
import MovingDay from "games/MovingDay";
import CommercialBreak from "games/CommercialBreak";

export default function Navigation() {
  return (
    <>
      <NavBar/>
      <MainContent/>
    </>
  );
}

function NavBar() {
  return (
    <nav className={styles['nav-bar']}>
      <NavBarLink target={'home'}>
        <img src={icon} className={styles['nav-bar-icon']}/>
      </NavBarLink>
      <NavBarLink target={'games'}>Play</NavBarLink>
    </nav>
  );
}

function MainContent() {
  return (
    <div className={styles['main-content']}>
      <Routes>
        <Route path="/home" element={<HomePage/>}/>
        <Route path="/games" element={<GameList/>}/>
        <Route path="/games/moving-day" element={<MovingDay/>}/>
        <Route path="/games/commercial-break" element={<CommercialBreak/>}/>
      </Routes>
    </div>
  );
}

type NavBarLinkProps = {
  children: ReactNode;
  target: string;
};

function NavBarLink(props: NavBarLinkProps) {
  return (
    <Link className={styles['nav-bar-link']} to={props.target}>
      {props.children}
    </Link>
  );
}
