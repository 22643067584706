import styles from "./Button.module.scss";

type ButtonVariant = 'primary' | 'secondary';

type ButtonProps = {
  onClick: () => void;
  variant?: ButtonVariant;
  text: string;
};

export default function Button(props: ButtonProps) {
  const variant = props.variant || 'primary';
  return (
    <button className={styles[`${variant}-button`]} onClick={props.onClick}>
      {props.text}
    </button>
  )
}
