import styles from "./Modal.module.scss";
import { ReactNode, MouseEvent } from 'react';

type ModalProps = {
  children: ReactNode;
  visible: boolean;
  setVisible: (_: boolean) => void;
};

export default function Modal(props: ModalProps) {
  const modalClick = (event: MouseEvent) => event.stopPropagation();
  return props.visible ? (
    <div className={styles['modal-background']} onClick={() => props.setVisible(false)}>
      <div className={styles['modal']} onClick={modalClick}>
        {props.children}
      </div>
    </div>
  ) : null;
}
