// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_nav-bar__2uPvF {
  background: black;
  color: white;
  font-family: sans-serif;
  position: fixed;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
}

.Navigation_nav-bar-icon__IouMT {
  height: 2rem;
}

.Navigation_nav-bar-link__Os1\\+H {
  text-decoration: none;
  color: white;
}

.Navigation_main-content__iMtsz {
  margin-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,qBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;AACF","sourcesContent":[".nav-bar {\n  background: black;\n  color: white;\n  font-family: sans-serif;\n  position: fixed;\n  padding: 0.5rem;\n  padding-left: 1rem;\n  padding-right: 3rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  margin: 0;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  box-sizing: border-box;\n}\n\n.nav-bar-icon {\n  height: 2rem;\n}\n\n.nav-bar-link {\n  text-decoration: none;\n  color: white;\n}\n\n.main-content {\n  margin-top: 4rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  box-sizing: border-box;\n  width: 100vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-bar": `Navigation_nav-bar__2uPvF`,
	"nav-bar-icon": `Navigation_nav-bar-icon__IouMT`,
	"nav-bar-link": `Navigation_nav-bar-link__Os1+H`,
	"main-content": `Navigation_main-content__iMtsz`
};
export default ___CSS_LOADER_EXPORT___;
