export const GAME_LENGTH = 30 * 60;
const MOVE_COST = 5 * 60;
const REPEAT_COST = 60;

export type GameCondition = 'active' | 'paused' | 'latent';

export type GameState = {
  realTimeLeft: number;
  customTime: number | null;
  moveTimes: number[];
  repeatTimes: number[];
  condition: GameCondition;
};

export function startGameState(): GameState {
  return {
    realTimeLeft: GAME_LENGTH,
    customTime: null,
    moveTimes: [],
    repeatTimes: [],
    condition: 'latent'
  };
}

export function gameTimeRemaining(gameState: GameState): number {
  const { realTimeLeft, moveTimes, repeatTimes } = gameState;
  return realTimeLeft - MOVE_COST * moveTimes.length - REPEAT_COST * repeatTimes.length;
}
