// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_standard-button__4J9S6, .Button_secondary-button__ZG7Hc, .Button_primary-button__\\+ubOi {
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.Button_primary-button__\\+ubOi {
  background: black;
  border: none;
  color: white;
}

.Button_secondary-button__ZG7Hc {
  border: 1px solid black;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;AACF;;AAEA;EAEE,iBAAA;EACA,YAAA;EACA,YAAA;AAAF;;AAIA;EAEE,uBAAA;EACA,YAAA;AAFF","sourcesContent":[".standard-button {\n  padding: 10px;\n  border-radius: 5px;\n  box-sizing: border-box;\n}\n\n.primary-button {\n  @extend .standard-button;\n  background: black;\n  border: none;\n  color: white;\n\n}\n\n.secondary-button {\n  @extend .standard-button;\n  border: 1px solid black;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standard-button": `Button_standard-button__4J9S6`,
	"secondary-button": `Button_secondary-button__ZG7Hc`,
	"primary-button": `Button_primary-button__+ubOi`
};
export default ___CSS_LOADER_EXPORT___;
