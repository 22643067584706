import styles from "./GameList.module.scss";
import { FaTruckMoving, FaTv } from "react-icons/fa";
import { ReactNode } from 'react';
import { Link } from "react-router-dom";

type GameDestination = {
  route: string;
  text: string;
  icon: ReactNode;
};

const links = [
  {
    route: 'commercial-break',
    text: "Commercial Break",
    icon: <FaTv/>
  },
  {
    route: 'moving-day',
    text: "Moving Day",
    icon: <FaTruckMoving/>
  }
];

export default function GameList() {
  return (
    <div className={styles['game-list']}>
      {links.map(link => <GameLink route={link.route} text={link.text} icon={link.icon}/>)}
    </div>
  );
}

type GameLinkProps = GameDestination;

function GameLink(props: GameLinkProps) {
  return (
    <Link to={`/games/${props.route}`} className={styles['game-link']}>
      <div className={styles['game-link-text']}>{props.text}</div>
      <div className={styles['game-link-icon']}>{props.icon}</div>
    </Link>
  );
}
