import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navigation from "Navigation";
import styles from "./App.module.scss";

function App() {
  return (
    <BrowserRouter>
      <div className={styles['global']}>
        <Navigation/>
      </div>
    </BrowserRouter>
  );
}

export default App;
