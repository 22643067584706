import { GameState, startGameState, gameTimeRemaining, GameCondition, GAME_LENGTH } from "./state";
import { useState, ReactNode, useEffect } from 'react';
import styles from "./MovingDay.module.scss";
import { FaPlay, FaPause, FaStop, FaTruckMoving } from 'react-icons/fa';
import { LuRepeat1 } from "react-icons/lu";
import { NumberInput, Modal, Button } from "components";
import { TimerReference } from "timerUtils";
import alarm from "../../alarm.mp3";

export default function MovingDay() {
  const [gameState, setGameState] = useState<GameState>(startGameState());
  const setCustomTime = (ct: number | null) => {
    setGameState({
      ...gameState,
      customTime: ct,
      realTimeLeft: ct === null ? GAME_LENGTH : ct * 60
    });
  };
  const [timer, setTimer] = useState<TimerReference | null>(null);
  const start = () => {
    setGameState({
      ...gameState,
      condition: 'active'
    });
    setTimer(setInterval(() => {
      setGameState(gs => {
        if (gs.condition === 'active') {
          return {
            ...gs,
            realTimeLeft: gs.realTimeLeft - 1
          }
        } else {
          return gs;
        }
      });
    }, 1000));
  };
  return (
    <div>
      <h2>Moving Day</h2>
      {gameState.condition !== 'latent' ? (
        <TimerDisplay state={gameState} setState={setGameState} timer={timer}/>
      ) : (
        <div className={styles['start-section']}>
          <div className={styles['custom-time-row']}>
            <label className={styles['custom-time-label']}>Custom Time (Minutes):</label>
            <NumberInput value={gameState.customTime} setValue={setCustomTime} extraClasses={styles['custom-time']}/>
          </div>
          <div className={styles['start-button']} onClick={start}>START</div>
        </div>
      )}
    </div>
  );
}

type TimerProps = {
  state: GameState;
  setState: (_: GameState) => void;
  timer: TimerReference | null;
};

function TimerDisplay(props: TimerProps) {
  const [stopModalOpen, setStopModalOpen] = useState(false);
  const timeRemaining = gameTimeRemaining(props.state);
  const [alarmPlaying, setAlarmPlaying] = useState(false);
  const [alarmAudio, setAlarmAudio] = useState<HTMLAudioElement>(new Audio(alarm));
  const playPause = () => {
    props.setState({
      ...props.state,
      condition: props.state.condition === 'active' ? 'paused' : 'active'
    });
  };
  const repeat = () => {
    props.setState({
      ...props.state,
      repeatTimes: props.state.repeatTimes.concat([props.state.realTimeLeft])
    });
  };
  const move = () => {
    props.setState({
      ...props.state,
      moveTimes: props.state.moveTimes.concat([props.state.realTimeLeft])
    });
  };
  const stop = () => {
    alarmAudio.pause();
    props.setState(startGameState());
    if (props.timer !== null) {
      clearInterval(props.timer);
    }
  };
  const stopButtonEffect = () => {
    if (timeRemaining <= 0) {
      stop();
    } else {
      setStopModalOpen(true);
      props.setState({
        ...props.state,
        condition: 'paused'
      });
    }
  };
  const resume = () => {
    setStopModalOpen(false);
    props.setState({
      ...props.state,
      condition: 'active'
    });
  };

  useEffect(() => {
    if (timeRemaining <= 0 && !alarmPlaying) {
      alarmAudio.play();
      setAlarmPlaying(true);
    }
  }, [timeRemaining, alarmPlaying]);
  return (
    <div className={styles['timer']}>
      <div className={styles['time-left-display']}>
        {formatMinute(timeRemaining)}
      </div>
      <div className={styles['button-row']}>
        <PlayPauseButton condition={props.state.condition} onClick={playPause}/>
        <RepeatButton onClick={repeat}/>
        <MoveButton onClick={move}/>
      </div>
      <div className={styles['stop-button-wrapper']}>
        <StopButton onClick={stopButtonEffect}/>
        <Modal visible={stopModalOpen} setVisible={setStopModalOpen}>
          <h3>Are you sure you want to stop?</h3>
          <div>The game will reset and your progress will be lost.</div>
          <div className={styles['modal-button-row']}>
            <Button variant="secondary" text="Resume Game" onClick={resume}/>
            <Button text="End Now" onClick={stop}/>
          </div>
        </Modal>
      </div>
    </div>
  );
}

function formatMinute(seconds: number): string {
  const secondsMod = Math.max(0, seconds % 60);
  const secondsText = `${secondsMod < 10 ? "0" : ""}${secondsMod}`;
  return `${Math.max(0, Math.floor(seconds / 60))}:${secondsText}`;
}

type ButtonProps = {
  onClick: () => void;
};

type PlayPauseButtonProps = ButtonProps & {
  condition: GameCondition;
};

function PlayPauseButton(props: PlayPauseButtonProps) {
  return (
    <GameButton onClick={props.onClick}>
      {props.condition === 'paused' ? <FaPlay/> : <FaPause/>}
    </GameButton>
  );
}

type RepeatButtonProps = ButtonProps;

function RepeatButton(props: RepeatButtonProps) {
  return (
    <GameButton onClick={props.onClick}>
      <LuRepeat1/>
    </GameButton>
  );
}

type MoveButtonProps = ButtonProps;

function MoveButton(props: MoveButtonProps) {
  return (
    <GameButton onClick={props.onClick}>
      <FaTruckMoving/>
    </GameButton>
  );
}

type StopButtonProps = ButtonProps;

function StopButton(props: StopButtonProps) {
  return (
    <GameButton onClick={props.onClick}>
      <FaStop/>
    </GameButton>
  );
}

type GameButtonProps = ButtonProps & {
  children: ReactNode;
};

function GameButton(props: GameButtonProps) {
  return (
    <div className={styles['game-button']} onClick={props.onClick}>
      {props.children}
    </div>
  );
}
